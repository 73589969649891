/* stylelint-disable selector-max-class */
/* stylelint-disable block-no-empty */
$main-width: 1200px;
$main-width-small: 1104px;
$main-width-large: 1344px;
$padding: 48px;

.mainWrapper {
  width: 100%;
  min-height: calc(100% - 53px);
  background-color: #fff;
}

.main {
  width: 100%;

  // min-width: $main-width + $padding * 2;
  min-height: calc(100% - 53px);
  padding: 0 $padding;

  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: SSFProDisplay !important;

  * {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: SSFProDisplay !important;
  }

  @media screen and (max-width: 1200px) {
    min-width: $main-width-small;
    margin: 0 auto;
  }

  @media (min-width: 1200px) and (max-width: 1440px) {
    width: 100%;
    padding: 0 $padding;
  }

  @media screen and (min-width: 1440px) {
    width: $main-width-large;
    padding: 0;
    margin: 0 auto;
  }
}

// search Area
.searchArea {
  display: flex;
  flex-direction: column;
  min-width: $main-width-small;
  padding: 0 48px;

  .searchContainer {
    // overflow: hidden;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),
      linear-gradient(0deg, rgba(224, 242, 255, 0.4) 0%, rgba(224, 242, 255, 0.4) 100%),
      linear-gradient(0deg, #ebf3ff 0%, #ebf3ff 100%), #f8f9fa;
    border-radius: 16px;
  }

  .searchAreaBox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: $main-width-large;
    height: 260px;
    margin: 0 auto;
  }

  @media (max-width: 1442px) {
    .searchAreaBox {
      overflow: hidden;
      border-radius: 16px;
    }
  }

  .searchAreaImage {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;

    // border-radius: 16px;
  }

  .searchAreaBg {
    position: absolute;
    bottom: 0;
    flex-shrink: 0;
    width: 178.114px;
    height: 255.597px;
  }

  .searchAreaBg2 {
    position: absolute;
    top: -30%;
    flex-shrink: 0;
    width: 575px;
    height: 304px;
  }

  .searchAreaBgImg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    width: 244px;
    height: 100%;
    object-fit: cover;
  }

  .searchAreaJp {
    position: absolute;
    bottom: -114px;
    left: -72px;
    flex-shrink: 0;
    width: 359px;
    height: 384px;
  }

  .searchAreaHeader {
    position: relative;
    z-index: 3;
    margin-bottom: 36px;
  }

  .searchAreaTitle {
    position: relative;
    z-index: 2;
    display: none;
    margin-bottom: 32px;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #000;
    text-align: center;

    // letter-spacing: 0.36px;
  }

  .searchAreaInput {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 64px;
    padding: 0 210px;
    margin: 0 auto;
    text-align: initial;
  }

  .searchAreaList {
    position: relative;
    z-index: 2;
    display: flex;
    gap: 12px;
    width: 70%;
    margin-top: 0;
  }

  .searchAreaPopular {
    flex-shrink: 0;
    height: 42px;
    padding-right: 19px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    color: #000;
    letter-spacing: 0.12px;
    list-style: none;
  }

  .searchAreaItems {
    position: relative;
    top: 24px;
    z-index: 2;
    display: flex;
    flex-flow: row wrap;
    gap: 40px 4px;
    align-items: center;
    justify-content: center;
    height: 60px;
    overflow: hidden;
  }

  .searchAreaItem {
    display: flex;
    align-items: center;
    padding: 2px;
    cursor: pointer;
  }

  .searchAreaItemLink {
    display: flex;
    gap: 8px;
    align-items: center;
    max-width: 255px;
    padding: 8px 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: linear-gradient(
        0deg,
        var(--global-alpha-white-white-70, rgba(255, 255, 255, 0.7)) 0%,
        var(--global-alpha-white-white-70, rgba(255, 255, 255, 0.7)) 100%
      ),
      var(--global-alpha-white-white-10, rgba(255, 255, 255, 0.1));
    backdrop-filter: blur(6px);
    border: 1px solid var(--global-alpha-brand-brand-10, rgba(28, 112, 238, 0.1));
    border-radius: 12px;
    box-shadow: 0 2px 5px 0 rgba(0, 102, 255, 0.05);
  }

  .searchAreaItemLink:hover {
    background: linear-gradient(
        0deg,
        var(--global-alpha-white-white-70, rgba(255, 255, 255, 0.7)) 0%,
        var(--global-alpha-white-white-70, rgba(255, 255, 255, 0.7)) 100%
      ),
      var(--global-alpha-white-white-10, rgba(255, 255, 255, 0.1));
    backdrop-filter: blur(6px);
    border: 1px solid var(--global-alpha-brand-brand-50, rgba(28, 112, 238, 0.5));
    box-shadow: 0 0 0 2px rgba(28, 112, 238, 0.2);
  }

  .searchAreaItemLink img {
    display: none;

    @media screen and (max-width: 1200px) {
      display: none;
    }

    @media (min-width: 1200px) and (max-width: 1440px) {
      display: block;
    }

    @media screen and (min-width: 1440px) {
      display: block;
    }
  }

  .searchAreaItemText {
    width: 100%;
    overflow: hidden;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #000;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :global {
    /* stylelint-disable-next-line selector-class-pattern */
    .ant-cascader-menus .ant-cascader-menu {
      max-height: 320px;
    }
  }
}

// jobsHiring
.jobsHiring {
  position: relative;
  display: flex;
  flex-direction: column;

  .jobsHiringBox {
    position: relative;
    width: 100%;
    padding: 24px 16px 32px;
    margin: 0 auto;
    margin-top: 24px;
    background: #f8f9fa;
    border-radius: 16px;

    .jobsH2TitleWrap {
      display: flex;
      justify-content: space-between;
    }
  }

  h2 {
    padding-bottom: 4px;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    color: #000;
  }

  .improve {
    padding-bottom: 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #000;
    letter-spacing: 0.12px;

    a,
    span {
      font-weight: 700;
      color: #000;
      text-decoration: none;
    }
  }

  .jobTabWrap {
    position: relative;
  }

  .jobTab {
    position: absolute;
    top: 6px;
    right: 0;
    display: flex;
    align-items: center;

    .jobTabAvatarWrapper {
      position: relative;
      width: 28px;
      height: 28px;
    }

    .jobTabAvatar {
      overflow: hidden;
      background: #ebeef0;
      border: 1px solid #ebeef0;
      border-radius: 50%;
    }

    .jobTabAvatarWrapper svg {
      position: absolute;
      right: -6px;
      bottom: 0;
    }

    .jobTabAvatarVip .jobTabAvatar {
      border-color: #ffe1d0;
    }

    .jobTabText {
      margin-left: 8px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      color: #000;
    }

    .jobTabInfo {
      padding: 0 8px;
      margin-left: 8px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      color: #1c70ee;
      cursor: pointer;

      &:hover {
        color: #0d6eff;
      }
    }
  }

  :global {
    /* stylelint-disable-next-line selector-class-pattern */
    .ant-tabs-tab {
      padding: 12px !important;

      // width: calc(100% / 6);
      margin: 0 !important;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .ant-tabs-nav-list {
      gap: 16px;

      // width: 100%;
      height: 44px;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .ant-tabs-nav::before {
      border-bottom: 1px solid #e0e8f5 !important;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .ant-tabs .ant-tabs-tab-btn {
      // width: 100%;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      color: #000;
      text-align: center;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-size: 18px;
      font-weight: 600;
      color: #000;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .ant-tabs .ant-tabs-ink-bar {
      background: #000 !important;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .ant-tabs-tab-btn {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .content {
    display: flex;
    gap: 14px;
    padding-top: 10px;

    .jobDetail {
      --sticky-top: 100px;

      position: sticky;
      top: var(--sticky-top);
      flex: 1;
      width: 0;

      // width: calc(100% - 546px);
      min-height: 215px;
      max-height: calc(100vh - var(--sticky-top));
      background-color: #fff;
      border-radius: 16px;
    }

    .list {
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      gap: 12px 16px;
      width: 518px;
      padding-top: 2px;
      overflow-y: visible;

      &::-webkit-scrollbar {
        width: 6px;
      }

      /* 设置滚动条滑块的样式 */
      &::-webkit-scrollbar-thumb {
        background-color: #ddd;
        border-radius: 5px;
      }

      /* 设置滚动条轨道的样式 */
      &::-webkit-scrollbar-track {
        background-color: #f8f9fa;
      }

      /* 当鼠标悬停在滚动条滑块上时的样式 */
      &::-webkit-scrollbar-thumb:hover {
        background-color: #ddd;
      }
    }

    .listItem {
      margin-right: 2px;
      margin-left: 2px;
      cursor: pointer;
      background: #fff;
      border: 1px solid #fff;
      border-radius: 16px;
      box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);

      .wrapper {
        padding: 16px 24px;

        // border: 1px solid #fff;
        border-radius: 16px;
      }

      .salary {
        display: flex;
        flex-direction: column;
        padding-top: 2px;
        overflow-y: hidden;
      }

      .salaryText {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        color: #1c70ee;
      }

      .month {
        margin-left: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        color: #000;
      }
    }

    .listItemActive {
      border: 1px solid rgba(0, 102, 255, 0.5);
      box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.1), 0 4px 6px 0 rgba(162, 183, 203, 0.1);
    }

    .listItem:hover {
      border: 1px solid rgba(0, 102, 255, 0.5);
      box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.1), 0 4px 6px 0 rgba(162, 183, 203, 0.1);
    }

    .listTag {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      padding-top: 6px;
    }

    .jobCardLocationItem {
      display: inline-block;
      height: 24px;
      padding: 0 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #3b3c3c;
      background: #f8f9fa;
      border-radius: 8px;
    }

    .listCompany {
      display: flex;
      align-items: center;
      padding-top: 10px;
      margin-top: 10px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: #000;
      border-top: 1px solid #eeeff0;

      .listCompanyLogo {
        flex-shrink: 0;
        width: 28px;
        height: 28px;
        margin-right: 8px;
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 6px;
        outline: none;
      }
    }

    // .jobHireCompanyName {
    //   font-size: 14px;
    //   font-style: normal;
    //   font-weight: 500;
    //   line-height: 24px;
    //   color: #3b3c3c;
    // }

    .cardLoading {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      height: 100%;
      background: #fff;
      border-radius: 16px;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    .cardLoadingContent {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
    }

    .cardLoadingContent svg {
      animation: spin 1s linear infinite;
    }

    .cardNetworkError {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      height: 100%;
      background: #fff;
      border-radius: 16px;
    }

    .cardNetworkErrorContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .cardNetworkErrorIcon {
      width: 120px;
      height: 120px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .cardNetworkErrorText {
      margin-top: 24px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      color: #000;
    }

    .cardNetworkErrorButton {
      display: flex;
      gap: 6px;
      align-items: center;
      justify-content: center;
      height: 36px;
      padding: 12px 32px;
      margin-top: 30px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      color: #1c70ee;
      background-color: #fff;
      border: 1px solid #1c70ee;
      border-radius: 8px;
    }

    .cardNetworkErrorButton:hover {
      color: #0055d4;
      border-color: #0055d4;
    }
  }

  .contentRow {
    .listRow {
      flex-flow: row wrap;
      width: 100%;

      .listItemRow {
        width: calc(50% - 8px);
        margin: 0;
      }
    }

    .jobDetail {
      display: none;
    }
  }

  .jobHireTop {
    &:hover {
      .jobHireTopTitle {
        color: #1c70ee;
      }
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .jobHireTopTitle {
    overflow: hidden;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #000;
  }

  .jobHireTopTitleAndCardColumn {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span:first-child {
      max-width: calc(100% - 100px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .jobHireTitleUrgent {
    display: inline-flex;
    align-items: center;
    height: 22px;
    padding: 0 6px;
    margin-left: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #000;
    vertical-align: text-bottom;
    background: #3ceaf6;
    border-radius: 6px;

    /* stylelint-disable-next-line no-descending-specificity */
    svg {
      vertical-align: middle;
    }
  }

  .jobTabLoading {
    position: relative;
    height: 700px;
  }

  .moreJob {
    display: flex;
    justify-content: center;
    padding-top: 24px;
    font-style: normal;
    font-weight: 700;
    color: #000;
  }

  .moreJobText {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 32px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 8px;
  }

  .moreJobText:hover {
    border: 1px solid rgba(0, 102, 255, 0.5);
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.1);
  }

  .viewMore {
    display: inline-flex;
    align-items: center;
    padding-top: 6px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #515151;
    cursor: pointer;

    /* stylelint-disable-next-line no-descending-specificity */
    svg {
      margin-left: 4px;
    }
  }
}

.emptyContent {
  padding: 120px 0;
}

// Companies
.companies {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  padding-top: 54px;

  .companiesBox {
    width: 100%;
  }

  .companiesTitle {
    padding: 0 8px;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    color: #000;
  }

  .companiesDesc {
    padding: 0 8px;
    margin-top: 12px;
    margin-bottom: 26px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #000;
    letter-spacing: 0.135px;
  }

  .companiesDescNum {
    font-weight: 800;
    color: #1c70ee;
  }

  .companiesList {
    box-sizing: border-box;
    width: 100%;

    // padding: 2px;
    overflow: hidden;
    list-style: none;
  }

  .companiesItem {
    --margin-left: 10px;

    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    float: left;
    width: calc(33.33% - (var(--margin-left) * 2) / 3);
    height: 512px;
    padding: 8px 8px 12px;
    margin-right: var(--margin-left, 10px);
    margin-bottom: 24px;
    overflow: hidden;
    background: #f8f9fa;
    border: 1px solid #f8f9fa;
    border-radius: 16px;
  }

  // .companiesItem:hover {
  //   border-color: rgba(0, 102, 255, 0.5);
  //   box-shadow: 0 4px 16px 0 rgba(0, 102, 255, 0.1), 0 0 0 2px rgba(0, 102, 255, 0.1);
  // }

  .companiesItem:nth-child(3n) {
    margin-right: 0;
  }

  .companyCardTop {
    display: flex;
    flex-shrink: 0;
    gap: 20px;
    align-items: center;
    height: 88px;
    padding: 6px 20px;
    overflow: hidden;
    background: #fff;
    border-radius: 12px;
  }

  .companyCardTopTitle p {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: top;
  }

  .companyCardItemTitle p {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: top;
  }

  .companyCardTop:hover .companyCardTopTitle p {
    color: #1c70ee;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .companyCardTop img {
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 8px;
  }

  .companyCardTopTitle {
    display: flex;
    width: 100%;
    overflow: hidden;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .companyCardTopDesc {
    overflow: hidden;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .companyCardTopContent {
    width: 100%;
    overflow: hidden;
  }

  .companyCardContent {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    margin-top: 8px;
    background: #fff;
    border-radius: 12px;
  }

  .companyCardItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
    height: 120px;
    padding: 16px 20px 0;

    // border-bottom: 1px solid #e0e8f5;
  }

  .companyCardBottom {
    display: flex;
    flex: 1 0;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    margin-top: 14px;
  }

  .companyCardBottomViewMore {
    display: flex;
    gap: 4px;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    padding: 0 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #111212;
    text-align: center;
    cursor: pointer;
  }

  .companyCardBottomViewMore:hover {
    color: #1c70ee;

    path {
      stroke: #1c70ee;
    }
  }

  .companyCardItemLink {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 1px solid #e0e8f5;
  }

  .companyCardItem:last-child .companyCardItemLink {
    border-bottom: none;
  }

  .companyCardItemTitle {
    display: flex;
    width: 100%;
    overflow: hidden;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .companyCardTopTitle:hover p {
    overflow: visible;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .companyCardItemTitle:hover p {
    overflow: visible;
  }

  .companyCardItemLink:hover .companyCardItemTitle p {
    color: #1c70ee;
  }

  .companyCardItemSalaryText {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #1c70ee;
  }

  .companyCardItemSalaryUnit {
    margin-left: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #000;
  }

  .companyCardItemLink:hover .companyCardItemSalaryUnit {
    color: #1c70ee;
  }

  .companyCardItemDesc {
    width: 100%;
    overflow: hidden;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: #3b3c3c;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .companiesMore {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
  }

  .companiesMoreLink {
    padding: 12px 32px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: #000;
    cursor: pointer;
    background: #f8f9fa;
    border: 1px solid #f8f9fa;
    border-radius: 8px;
  }

  .companiesMoreLink:hover {
    border: 1px solid rgba(0, 102, 255, 0.5);
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.1);
  }
}

// Job Function Ads
.recoContent {
  display: flex;
  justify-content: space-between;
  width: 792px;
  height: 332px;

  .recoItem {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(50% - 8px);
    height: 100%;
    padding: 24px 32px;
    overflow: hidden;
    border-radius: 16px;
  }

  .recoItemFirst {
    background: linear-gradient(
        319deg,
        rgba(126, 242, 255, 0.2) -1.36%,
        rgba(152, 245, 255, 0) 45.92%
      ),
      linear-gradient(0deg, #d7eaff 0%, #d7eaff 100%), #f3f5f8;
    box-shadow: 0 8px 10px -3px rgba(195, 199, 203, 0.2);
  }

  .recoItemSecond {
    background: linear-gradient(0deg, #fff 0%, #fff 100%), #f3f4f6;
    border: 1px solid rgba(0, 102, 255, 0.1);
    box-shadow: 0 8px 10px -3px rgba(195, 199, 203, 0.2);
  }

  .recoItemImage {
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;
    background: #fff;
    border-radius: 16px;
  }

  .recoItemTitle {
    margin-top: 14px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #000;
    text-align: center;
  }

  .recoItemSubtitle {
    margin-top: 4px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #000;
    text-align: center;
  }

  .recoItemButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .recoItemButtonText {
    font-weight: 600;
  }
}

.recoBanner {
  display: flex;
  gap: 4px;
  justify-content: space-between;
  width: 792px;
  height: 332px;
  border-radius: 16px;
  box-shadow: 0 8px 14px -2px rgba(162, 183, 203, 0.15);

  :global {
    .ant-carousel {
      position: relative;
      z-index: 1;

      /* stylelint-disable-next-line selector-class-pattern */
      .slick-slider {
        /* stylelint-disable-next-line selector-class-pattern */
        .slick-dots {
          inset-inline-start: 28px;
          justify-content: left;

          li {
            width: 7px;
            height: 7px;
            margin-inline: 3px;
            overflow: hidden;
            background: #fff;
            border-radius: 47px;
            box-shadow: 0 1px 2px 0 #e5eaef;
          }

          /* stylelint-disable-next-line selector-class-pattern */
          li.slick-active {
            width: 20px;
            height: 7px;
          }
        }

        div {
          outline: none;
        }
      }
    }
  }

  .recoBannerCarousel {
    width: 540px;
    overflow: hidden;
    background: #e0eeff;
    border-radius: 16px 0 0 16px;
  }

  .recoBannerContent {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 248px;
  }

  .recoBannerItem {
    flex: 1;
    overflow: hidden;
    background-color: #e0eeff;
  }
}

.functionMain {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
}

.functionFilter {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  flex: 1;

  // width: calc(50% - 8px);

  width: 0;
  height: 332px;
  color: #515151;
  border: 1px solid #e0e8f5;
  border-radius: 16px;
  box-shadow: 0 8px 14px -2px rgba(162, 183, 203, 0.15);

  .subContainerWarp {
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
  }

  .subChildContainer {
    position: absolute;
    z-index: -1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    pointer-events: none;
    opacity: 0;
  }

  .mainItems {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px 16px;
    overflow: hidden;
    background: #fff;
    border-radius: 16px;
  }

  .mainItem {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 44px;
    padding: 8px 10px;
    margin-bottom: 8px;
  }

  .mainItem.isHover {
    background: #f8f9fa;
    border-radius: 10px;
  }

  .mainItem .mainTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    overflow: hidden;
    font-size: 16px;
    color: #515151;
  }

  .mainItem .mainTitleFirst {
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 700;
    color: #000;

    &:hover {
      color: #000;
    }
  }

  .mainItem.isHover .mainTitle {
    color: #1c70ee;
  }

  .mainItem:hover .mainTitle {
    color: #1c70ee;
  }

  .mainItem .mainTitle svg {
    width: 12px;
  }

  .mainItem .mainTitle .subContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
  }

  .mainItem .mainTitleSub {
    display: block;
    margin-left: 20px;
    overflow: hidden;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: #3b3c3c;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      color: #1c70ee;
    }
  }

  .mainItem .more {
    font-size: 12px;
    color: #707070;
  }

  .pagination {
    position: absolute;
    bottom: 20px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0 26px 0 20px;
  }

  .pagination label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 8px;
    margin-right: 12px;
    font-size: 14px;
    font-weight: 500;
    background: #fafbfc;
    border-radius: 8px;
  }

  .pagination .svg {
    margin: 0 8px;
  }

  .pagination button {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 4px;
    appearance: none;
    background: none;
    background: #fafbfc;
    border: none;
    border-radius: 8px;

    &:last-child {
      margin: 0;
    }
  }

  .sectionContainer {
    --width: 540px;

    position: absolute;
    top: -1px;
    bottom: 0;
    left: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    width: calc(var(--width) + 18px);
    padding-left: 16px;

    // background-color: #fff;
    // transform: translateX(calc(var(--width) - 17px));
  }

  .sectionMain {
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    height: 333px;
    padding: 20px 16px 15px;
    background-color: #f8f9fa;
    border-radius: 16px 0 0 16px;
  }

  .sectionBtn:hover path {
    fill: #1c70ee;
  }

  .sectionTitle {
    display: block;
    margin-bottom: 12px;
    margin-left: 2%;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #000;
  }

  .section {
    flex: 1;
    overflow: hidden;
    overflow-y: scroll;
  }

  .sectionContainer::-webkit-scrollbar {
    right: 10px;
    width: 5px;
  }

  .sectionContainer::-webkit-scrollbar-thumb {
    background: #f0f0f0;
    border-radius: 10px;
  }

  .sectionContainer .sectionItems {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .sectionContainer .sectionItems .sectionName {
    margin-bottom: 8px;
    margin-left: 2%;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    color: #7d7d7d;
  }

  .sectionContainer .sectionItems .subItems {
    display: flex;
    flex-flow: row wrap;
    gap: 8px 24px;
    justify-content: flex-start;

    // padding-left: 14px;
    // margin-top: 8px;
    margin-bottom: 24px;
    margin-left: 2%;
  }

  .sectionContainer .sectionItems .subItems .subItem {
    // width: 23%;
    // margin-bottom: 8px;

    // margin-left: 2%;
    overflow: hidden;
    line-height: 24px;
  }

  .sectionContainer .sectionItems .subItems .subItem .linkText {
    overflow: hidden;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: #3b3c3c;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sectionContainer .sectionItems .subItems .subItem:hover .linkText {
    width: fit-content;
    color: #1c70ee;
    text-overflow: none;
  }
}

// searchArea fixed
.searchAreaFix {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;

  // height: 88px;
  padding: 15px 0;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%),
    rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(12.25px);
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.06);
  transition: 0.3s;
  animation: showTrans 0.25s ease-in-out both;

  .searchLogo {
    display: flex;
    flex-shrink: 0;
    margin-right: 84px;
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0 auto;

    .searchWrapper {
      padding: 0;
      margin: 0;
    }

    :global {
      .homeSearchPcContainer {
        margin-left: 48px;
        background: rgb(255, 255, 255);
        box-shadow: unset;
      }

      .yolo-searchArea-wrapper {
        align-self: auto;
        box-shadow: none;
      }

      .yolo-searchArea-wrapper:hover {
        box-shadow: 0 0 0 3px rgba(28, 112, 238, 0.1) !important;
      }
    }

    @media screen and (max-width: 1200px) {
      min-width: $main-width-small;
      margin: 0 auto;
    }

    @media (min-width: 1200px) and (max-width: 1440px) {
      width: 100%;
      padding: 0 $padding;
    }

    @media screen and (min-width: 1440px) {
      width: $main-width-large;
      padding: 0;
      margin: 0 auto;
    }
  }

  .shadeheader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
  }

  .download {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    // width: fit-content;
    min-width: 204px;
    height: 56px;
    padding: 0 8px 0 16px;
    margin-left: 20px;
    font-size: 14px;
    color: #136fd3;
    word-break: keep-all;
    cursor: pointer;
    background: #f4f5f6;
    border-radius: 12px;

    @media (max-width: 799px) {
      display: none;
    }

    .icon {
      float: left;
      font-size: 32px;
      line-height: 18px;
      letter-spacing: 1px;
    }

    .downloadDescribe {
      margin-left: 11px;

      h5 {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        color: #000;
      }

      span {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        color: #000;
      }
    }

    &:hover {
      background: #ebeef0;

      .popover {
        transition: transform 0.4s;
        transform: translateY(0%);
      }
    }
  }

  .downloadNoOpacity {
    background: #eef1f3;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .popover {
    width: 100%;
    height: 0;
    padding-top: 0;
    overflow: hidden;
    transition: height 0.4s;
    transition: 0.4s;

    .popoverContainer {
      height: 100%;
      padding: 0 204px 0 180px;

      @media screen and (max-width: 1200px) {
        min-width: $main-width-small;
        margin: 0 auto;
      }

      @media (min-width: 1200px) and (max-width: 1440px) {
        width: 100%;

        // padding: 0 $padding;
      }

      @media screen and (min-width: 1440px) {
        width: $main-width-large;

        // padding: 0;
        margin: 0 auto;
      }
    }

    .popoverMain {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      .coverDefault {
        position: relative;
        flex: 1;

        img {
          position: absolute;
          right: -10px;
          bottom: -116px;
          width: 293px;
          height: auto;
        }
      }

      .info {
        display: flex;
        gap: 32px;
        align-items: center;

        /* stylelint-disable-next-line max-nesting-depth */
        .qrcode {
          width: 142px;
          height: 142px;
          border-radius: 12px;

          /* stylelint-disable-next-line max-nesting-depth */
          img {
            height: 100%;
          }
        }

        /* stylelint-disable-next-line max-nesting-depth */
        .appStore {
          display: flex;
          gap: 6px;
          align-items: center;
          margin-top: 14px;
        }

        /* stylelint-disable-next-line max-nesting-depth */
        .getApp {
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
          color: #111212;
        }

        /* stylelint-disable-next-line max-nesting-depth */
        .getAppTips {
          display: -webkit-box;
          width: 421px;
          margin-top: 4px;
          overflow: hidden;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          color: #111212;
          text-align: left;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}

/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes showTrans {
  0% {
    transform: translate(0, -25px);
  }

  100% {
    transform: translate(0, 0);
  }
}

.inputPlaceholder {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;

  /* stylelint-disable selector-class-pattern */
  .inputPlaceholder_center {
    padding: 0 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  /* stylelint-enable selector-class-pattern */
}

/* stylelint-disable-next-line no-descending-specificity */
.listCompany {
  display: flex;
  align-items: center;
  padding-top: 12px;
  margin-top: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #000;
  border-top: 1px solid #ebeef0;

  /* stylelint-disable-next-line no-descending-specificity */
  .listCompanyLogo {
    position: relative;
    display: flex;

    /* stylelint-disable-next-line no-descending-specificity */
    img {
      flex-shrink: 0;
      width: 28px;
      height: 28px;
      margin-right: 9px;
      border: 1.333px solid #fff;
      border-radius: 50px;
      outline: none;
      box-shadow: 0 5.333px 8px 0 rgba(162, 183, 203, 0.1);
    }
  }

  .listCompanyLogoActive {
    &::before {
      position: absolute;
      right: 10px;
      bottom: 0;
      display: block;
      width: 9px;
      height: 9px;
      content: "";
      background: #34c759;
      border: 1px solid #fff;
      border-radius: 50%;
    }
  }

  .listCompanyReply {
    flex: 1 1 calc(50% - 8px);
    max-width: calc(50% - 8px);
    padding-left: 8px;
    margin-left: 22px;
    margin-left: auto;
    overflow: hidden;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #4b4c4d;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;

    /* stylelint-disable-next-line no-descending-specificity */
    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      color: #000;
    }
  }

  .jobHireRecruiterName {
    overflow: hidden;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #3b3c3c;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.listCompanyRecruiter {
  .listCompanyLogo {
    /* stylelint-disable-next-line no-descending-specificity */
    img {
      width: 28px;
      height: 28px;
      border-radius: 6.222px;
    }
  }
}

.jobHireCompanyName {
  display: block;
  flex: 1 1 calc(50% - 8px);
  align-items: center;
  width: calc(50% - 8px);
  padding-right: 8px;
  overflow: hidden;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #646566;
  text-overflow: ellipsis;
  white-space: nowrap;

  /* stylelint-disable-next-line no-descending-specificity */
  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #646566;
  }

  b {
    flex: 0 0 20px;
    padding-right: 1px;
    padding-left: 1px;
    color: #acaeb1;
  }

  .jobHireCompanyNameFullName {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #000;
  }
}
